@import '../../../../App';

.onboarding-page-title {
    font: {
        family: $font-family-primary;
        style: normal;
        weight: bold;
        size: 40px;
    }
    line-height: 48px;
    letter-spacing: -0.02em;
    color: #666666;
    margin-bottom: 30px;

    &.no-margin {
        margin-bottom: 0;
    }
}