@import '../../../colors.scss';
@import '../../_shared/_shared.scss';

#sidebar-menu-container {
  background-color: $white;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  left: 0;
  padding-top: 0;
  top: 0;
  width: 200px;
  z-index: 900;
  font-weight: bold;

  .sidebar-brand {
    width: 50px;
    padding: 30px;
    margin: 0 auto;
    display: block;
  }

  .sidebar-menu {
    margin-top: 50px;
  }

  .sidebar-footer {
  }
}

.feedback-bold {
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 20px;
}

.feedback-text {
  font-size: 10px;
  font-weight: normal;
}

.feedback-root {
  text-align: center;
  margin: 32px 35px 22px;
}

.feedback-button {
  width: 130px;
}
/*
.name-icon-container {
  display: flex;
  align-items: center;
  color: $text-main;
  height: 40px;
  padding: 1px 0;
  // border: 1px solid red;

  img {
    padding-left: 40px;
    height: 19px;
    width: 20px;
    margin-right: 20px;
  }

  svg {
    padding-left: 40px;
    height: 19px;
    width: 20px;
    margin-right: 20px;
  }
} */

.navbar-login {
  margin: 35px 0;

  // &:hover {
  //   font-size: 17px;
  //   text-decoration: underline $route-teal;
  // }
}

#line-break {
  &--top {
    border: 0.5px solid $med-grey;
    height: 0px;
    left: 0px;
    position: absolute;
    width: 108px;
    top: 280px;
    margin: 20px 0;
  }
  &--bottom {
    height: 0.5px;
    width: 100%;
    display: flex;
    margin: 20px 0;
    background-color: #666666;
  }
}

.merchant-display {
  text-align: center;
  width: 167px;
  margin: 0 20px;

  #line-break--bottom {
    margin-bottom: 10px;
    margin-left: -30px;
    width: calc(100% + 42px);
    margin-right: 0;
  }
}

.header-search-results {
  display: flex;
  flex-direction: column;
  width: 167px;
  padding: 0;
  border-radius: 0;
  background-color: $white;
  position: fixed;
  width: 200px;
  left: 0;
  bottom: 81px;
  padding-bottom: 13px;
}

.search-item-container {
  background-color: #fff;
  padding: 10px 20px 10px 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  line-height: 22px;

  font-size: 12px;
  font-weight: 600;

  color: #666666;
  text-align: left;

  &:hover {
    background-color: $background-med;
  }
}

.search-bar-display-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 32px;
  padding-right: 8px;
  position: relative;
  input {
    width: 100%;
  }
}

.close-placeholder {
  content: '';
  width: 25px;
  height: 15px;
  background: transparent;
  position: absolute;
  right: 0;
  display: block;
  cursor: pointer;
  z-index: 11;
}

.display-sign-in-options {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  padding: 15px 0;
}

#sign-in-as-container {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.merchant-name-display {
  padding: 34px 0;
  display: block;
}

#header-search-bar {
  margin: 0;
  border-bottom: 1px solid #666666;
  padding: 0;
  color: #666666;
  background-position-x: 100%;
  box-shadow: none;

  &:not(.active) {
    background-image: url('../../../../assets/images/multi-select.svg');
  }
}

.pointer {
  cursor: pointer;
}

.MuiBackdrop-root {
  background: rgba(0, 0, 0, 0.75) !important;
}

.hide {
  display: none;
}
