.terms {
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 19px;
   color: #666666;
   margin-top: 20px !important;
   display: block;
   
   a {
      color: #66C1CB;
      font-weight: 600;
   }
}