@import "../../../../assets/styles/colors";

$font : 'Titillium Web';
$text : #313942;

@mixin btn-mixin {
  border-radius: 3px;
  border-style: none;
  height: 35px;
  width: 100%;
  background-color: $route-teal;
  color: white;
  font-size: 15px;
  font-family: $font;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}

.creditcard-form {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font;
  width: 650px;
  height: 500px;
}

.creditcard-form-container {
  display: grid;
  grid-template-columns: 1fr;
  border: 1px solid $route-teal;
  border-radius: 5px;
  padding: 10px;
  height: 450px;
  width: 550px;

  @mixin form-rows {
    height: 35px;
    width: 550px;

    .field {
      display: grid;
      grid-template-columns: [label-s] 150px [lable-e input-s] 350px [input-e];
      height: 35px;
      border-bottom: 1px solid $route-teal;
      padding: 3px 0 3px 0;

      label {
        display: grid;
        grid-column: label-s/lable-e;
        align-items: center;
      }
      input:focus {
        outline: none;
      }

      .billing-input {
        grid-column: input-s/input-e;
        border: none;
        font-size: 15px;
        font-family: $font;
      }

      .billing-input::placeholder {
        font-size: 15px;
        color: rgb(165, 165, 165);
      }
    }
  }

  @for $i from 1 through 7 {
    .row-#{$i} {
      @include form-rows;
    }
  }

  #label-name {
    display: flex;
    flex-direction: row;
  }

  .row__creditcard {
    margin: 15px 0 15px 0;
    border-bottom: 1px solid $route-teal;
  }

  .submit-CC-button {
    align-self: flex-end;
    border-radius: 3px;
    border-style: none;
    height: 35px;
    margin-bottom: 10px;
    background-color: $route-teal;
    color: white;
    font-size: 15px;
    font-family: $font;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
  }
}
