@import '../../../colors';


.multisteps-placeholder {
    text-align: center;
    margin: 0 auto;
    position: relative;
    display: inline-block;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-left: 40px;
    display: block;
    width: 247px;


    &:before {
        content: ' ';
        background: linear-gradient(0deg, #FFFFFF 0%, #000 0.01%, #FFF 100%);
        width: 247px;
        height: 170px;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        z-index: -3;
    }

    .picture {
        max-width: 248px;
        position: relative;
        display: block;
        margin: 0 auto;
    }

    .logo {
        position: absolute;
        left: calc(50% - 20px);
        width: 41px;
        height: 41px;
        border-radius: 50%;
        top: calc(50% - 25px);
        object-fit: cover;
    }

    .cover {
        position: absolute;
        left: 0;
        width: 100%;
        height: 189px;
        top: 0;
        object-fit: cover;
        z-index: -1;
    }

    .text {
        position: absolute;
        top: 60px;
        right: -120px;
        z-index: 2;

        &:before {
            content: ' ';
            width: 135px;
            height: 2px;
            border-bottom: 2px solid #000;
            position: absolute;
            left: -145px;
            top: 5px;
        }

        &.text-logo {
            top: 135px;
            right: -55px;

            &:before {
                left: -123px;
                width: 110px;
            }
        }
    }

    .add-placeholder {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 30px;
        text-align: center;
        align-items: center;
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 14px);
        font-size: 13px;
    
    }
}

.multisteps-company-settings {
    position: relative;

    .steps {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: -80px;
        top: 10px;

        .step {
            background-color: white;
            border-radius: 50%;
            box-shadow: 0px 0px 6px 0px white;
            float: left;
            height: 24px;
            margin-left: 15px;
            position: relative;
            width: 24px;
            margin-bottom: 37px;
            border: 1px solid #5EC8DB;
            cursor: pointer;

            &.filled {
                background-color: #5EC8DB;
            }

            &.current {
                &:before {
                    content: '';
                    background-color: #5EC8DB;
                    position: absolute;
                    left: 0;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    top: 7px;
                    left: 7px;
                }
            }

            &:after {
                background-color: #5EC8DB;
                content: '';
                height: 1px;
                left: 100%;
                position: absolute;
                top: calc(100% + 27px);
                width: 55px;
                transform: rotate(90deg);
                left: -15px;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }

    .multisteps {
        height: 390px;
        overflow-x: hidden;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 8px;
            background: #C0C0C0;
        } 

        .multistep {
            margin-bottom: 20px;

            .title {
                font-family: $font;
                font-style: normal;
                font-weight: 600;
                font-size: 36px;
                line-height: 44px;
                /* or 122% */
                
                
                color: #2A333B;

                &.inactive {
                    color: #8997A5;
                }

                &.active {
                    color: rgba(94, 199, 219, 0.6);
                }
            }
    
            .description {
                font-family: $font;
                font-size: 16px;
                line-height: 19px;
                margin: 5px 0 25px 0px;
                color: #2A333B;
            }
    
            .file-info {
                margin-top: 25px;
                margin-bottom: 25px;
                display: flex;
                align-items: center;
    
                .file-info-image-container {
                    background: #FBFBFB;
                    border: 1px solid #CFCFCF;
                    box-sizing: border-box;
                    border-radius: 10px;
                    height: 42px;
                    width: 42px;

                    &.cover {
                        width: 72px;
                    }
                    
                    .file-info-image {
                        object-fit: cover;
                        width: 100%;
                        height: 42px;
                    }
                }
    
                .file-info-container {
                    margin-left: 15px;
    
                    .file-info-filesize {
                        margin-top: 5px;
                        color: #A4A7A7;
                    }
                }
            }

            .input-field {
                background: #FFFFFF;
                border: 2px solid #66C1CB;
                box-sizing: border-box;
                border-radius: 15px;
                padding: 19px 20px;
                width: calc(100% - 50px);
                margin-top: 15px;
            }
        }
    }
}