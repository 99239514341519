.mobile-device-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &-header {
    z-index: -1;
    text-align: center;
    color: #bbbbbb;
    margin: 100px 0 10px 0;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    font-style: normal;
  }
}

.mobile-outline {
  position: absolute;
  z-index: -5;
  box-shadow: 20px 24px 18px rgba(0, 0, 0, 0.15);
  border-radius: 50px;

}

.phone-stats {
  border-radius: 29.3972px 10px 10px 0;
  position: absolute;
  z-index: -6;
  padding: 5px 8px 100px 0;
  margin: 13px 0 800px 13px;
}

.card-content {
  display: flex;
  margin: 285px 0 0 15px;
  align-items: flex-end;
  position: absolute;
  z-index: -1;
  &-custom {
    position: absolute;
    margin: 326px 0 0 73px;
    font-size: 12px;
  }
}

.mobile-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.mobile-background {
  background-color: rgb(247, 247, 247);
  border-radius: 50px;
  z-index: -6;
  width: 302px;
  height: 623px;
  margin-top: -1px;
}

.phone-cover-image {
  object-fit: cover;
  position: absolute;
  margin-top: 10px;
  border-radius: 40px 40px 0 0;
  width: 300px;
  height: 171px;
  z-index: -10;
  &-placeholder {
    margin-top: 10px;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    display: flex;
    position: absolute;
    border-radius: 40px 40px 0 0;
    width: 300px;
    height: 171px;
    background-color: #5c676c;
    background-image: linear-gradient(#5c676c, #726f6f);
    z-index: -10;
    align-items: center;
    justify-content: center;
    &-content {
      margin-top: 30px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      display: flex;
    }
    span {
      margin-top: 5px;
    }
  }
}

.phone-logo-image {
  position: absolute;

  &-large {
    object-fit: cover;
    background-color: rgb(247, 247, 247);
    border: 5px solid white;
    position: absolute;
    margin: 150px 0 0 125px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    &-placeholder {
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px solid white;
      position: absolute;
      margin: 150px 0 0 125px;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: #2c373c;
      text-transform: uppercase;
      font-size: 9px;
      font-weight: 600;
    }
  }
  &-small {
    object-fit: cover;
    width: 100%;
    position: absolute;
    margin: 322px 0 0 35px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: rgb(247, 247, 247);
    &-placeholder {
      width: 100%;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      margin: 322px 0 0 35px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: #2c373c;
      text-transform: uppercase;
      font-size: 7px;
      font-weight: bold;
    }
  }
}

.phone {
  &-container {
    position: absolute;
    width: 300px;
    height: 100px;
    margin-top: 180px;
    display: flex;
  }
  &-company-name {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    margin-top: 40px;
  }
  &-orders-headers {
    font-weight: 600;
    position: absolute;
    margin: 80px 0 0 27px;
    font-size: 13px;
    span {
      // text-transform: uppercase;
      margin-left: 20px;
      font-size: 13px;
      color: #cccccc;
      text-align: center;
    }
  }
  &-orders-web-icon {
    margin-right: 3px;
  }
}

.phone-route-plus-icon {
  position: absolute;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 344px 0 0 58px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #66C1CB;
  text-transform: uppercase;
  font-size: 7px;
  font-weight: bold;
}
