.MuiTypography-body1 {
   font-family: Titillium Web, sans-serif !important;
   font-style: normal;
   font-weight: normal;
   font-size: 16px !important;
   line-height: 19px;
   
   color: #666666;
   display: inline  !important;
}

.MuiTypography-colorTextSecondary {
   font-family: Titillium Web, sans-serif !important;
   font-weight: bold !important;
   font-size: 16px !important;
   line-height: 19px;
   display: inline-block;
   margin-left: 3px !important;
}

.button-container {
   margin-top: 6px;
}

.paragraph + .paragraph {
   margin-bottom: 12px;
}

.nested-list {
   margin-top: 2px;

   .item-list {
      margin-left: 2px;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 7px;
      margin-top: 4px;
      cursor: pointer;

      span {
         letter-spacing: -0.001px;
         line-height: 19px;
      }

      .checkbox {
         margin-left: 2px;
         margin-top: -4px;
      }
   }

   .MuiListItemText-multiline {
      margin-left: 3px;
   }
}

.nested-bullet-list {
   margin: 20px 0;

   li {
      margin-left: 27px;
      display: flex;
      margin-bottom: 10px;
      font-family: Titillium Web;
font-size: 16px;
line-height: 19px;

color: #666666;

      span {
         display: block;
         margin-left: 27px;

         a {
            text-decoration: none;
            color: #66C1CB;
            font-weight: bold;

         }
      }
   }
}

.expert-step {
   margin-top: 28px;
   margin-bottom: 28px;
}

.self-step {
   margin-bottom: 33px;
}

.bullet {
   width: 6px;
   height: 6px;
   margin-top: 8px;
}

.route-package-protection {
   .coverage-type-coverage {
      display: flex;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #666666;
      cursor: pointer;


      border-top: 1px solid #666666;
      /* padding-top: 30px; */
      margin-top: 30px;
      margin-left: 30px;
      margin-right: 30px;
      padding: 0;
      padding-top: 30px;
      margin-bottom: 20px;

      padding-top: 20px;
    margin: 0;
    margin-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;

      .checkbox-square {
         border-top: 1px solid #666666;
    display: flex;
    align-self: self-start;
    padding: 0;
    margin-right: 30px;
      }
      img {
         height: auto;
         margin-top: -1px;
      }

      b {
         font-weight: 600;
      }

      p {
         margin-top: 2px;
      }
   }
}