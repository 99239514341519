@import "../../../../assets/styles/colors";

.connect-card {

  &-billing-input {
    font-size: 12px;
    line-height: 14px;
    width: 240px;
    height: 30px;
    margin: 8px 0;
    padding: 5px;
    background: #FFFFFF;
    border: 1px solid #666666;
    box-sizing: border-box;
    border-radius: 0;
    position: relative;
    margin: 0;
  }

  &-field {
      label{
      display:block;
        margin-bottom:8px;
        font-size: 16px;
line-height: 19px;

color: #666666;

      }

    margin: 20px 0;
    color: #333;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    padding: 0 30px;
  }

  &-double-column { 
    &-left {
      margin-right: 20px;
      padding-right: 0;
      padding-left: 20px;
    }
    width: 105px;
    display: inline-block;
    padding-right: 0;
    padding-left: 0;
    margin-left: 10px;
    margin: 0px 10px 20px 10px;

    .connect-card-billing-input {
      width: 100%;
    }
  }

  &-submit-CC-container{
    margin-top: 15px;
  }

  &-submit-CC-button{
      width:100%;
      border-radius:10px !important;
  }
}

.StripeElement {
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 10px;
    // width: 110px;
    width:100%;
    height: 30px;
    margin-top: 8px;
    padding:5px !important;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    border: 1px solid rgba(49, 57, 66, 0.4);
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;

    border-radius: 0;
    border: 1px solid #666666;
    box-sizing: border-box;
    color: #666;

    input::placeholder {
      color: #666 !important;
    }
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.connect-card-submit-CC-container {
  margin-top: 0;
  .button {
    margin-top: 0 !important;
  }
}

.connect-card-row-6 {
  .connect-card-field {
    margin-top: 0;
  }
}