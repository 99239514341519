@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700');

$route-teal: #31c8e0;
$route-dark: #666666;
$teal-gradient-light: #7cd6e8;
$teal-gradient-dark: #31c8e0;

$background: #1a1e37;
$headers: #303757;
$maps: #445072;
$text-lighter: rgb(197, 200, 216);
$text: #9799a8;
$text-darker: dimgray;
$text-darkest: rgb(43, 43, 43);
$banner: #eeeff2;

$purple: #8287ed;
$teal: #45dce5;

$pink: #ff6fb4;
$orange: #ff946e;

$violet: #645fe8;
$magenta: #c547fa;

$lavender: #aa54f8;
$pink-red: #e64688;

$salmon: #f6738c;
$hot-orange: #fe757e;
$hot-purple: #e858f5;
$white: white;

$awesome-blue: #009fd9;
$awesome-blue--disabled: rgba(0, 159, 217, 0.3);

$font: 'Titillium Web', sans-serif;

@mixin btn-mixin {
  border-radius: 3px;
  border-style: none;
  height: 35px;
  width: 100%;
  background-color: $route-teal;
  color: white;
  font-size: 15px;
  font-family: $font;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}

$background-new: #313942;
$sections: #262c46;
