@import "../../assets/styles/colors";

.flex {
  display: flex;
  height: 100%;
  width: 100%;
  
}

.link {
  color: $white;
  text-decoration: none;
}

.search-bar {
  background: $white;
  border-radius: 10px;
  box-sizing: border-box;
  width: auto;

  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 0;
  min-height: 42px;

  &:focus {
    outline-width: 0;  
  }

  background-image: url(../../assets/images/close-icon.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
}
