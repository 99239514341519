.link-merchant {
   .page-header {
      margin-top: 50px;
    margin-bottom: 40px;
   }

   .link-merchant-form {
      margin-top: 30px;

      input {
         width: 315px;
      }
   }
}