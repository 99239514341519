@import "../../_shared/_shared.scss";

.download-page-text {
  font-size: 13px;
  line-height: 15px;
  color: #666666;

  &-upper {
    font-size: 16px;
    line-height: 19px;
    color: #666666;
  }

  &-note {
    padding-top: 36px;
  }
}

.download-page-text-note > span {
  font-weight: 600;
}
.download-page-text > div > a {
  text-decoration: underline;
  color: #666666;
  font-weight: 600px;
  a:hover {
    cursor: pointer;
  }
}

.thank-you-page {
  &-top {
    padding: 40px 0px 10px 85px;
  }

  &-bottom {
    padding: 10px 0px 52px 130px;
  }

  &-phone-animation {
    position: absolute;
    left: 35px;
    width: 61.2px;
    height: 101px;
    top: 27px;
  }
}

.line-break--here {
  text-align: center;
  width: 167px;
  margin: 0 20px;

  #line-break--bottom {
    margin-bottom: 10px;
    margin-left: -30px;
    width: calc(100% + 42px);
    margin-right: 0;
  }
}
.toggle-on {
  color: #249d0e;
  white-space: nowrap;
}

.toggle-button {
  display: table-cell;
  padding: 10px 0px 6px 0px;
  &:hover {
    cursor: pointer;
  }
}

.thank-you-table-cell {
  display: table-cell;
  vertical-align: middle;
  padding-right: 143px;

  &-row {
    display: table-row;
  }
}

.saved-setting {
  text-align: center;

  &-logo {
    padding-bottom: 21px;
    padding-top: 10px;
  }

  &-button {
    max-width: 240px;
  }

  &-description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #5c676c;
    padding-bottom: 25px;
  }
}

.tracking-on {
  padding-left: 7px;
  padding-top: 10px;
}

.save-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #5c676c;
  padding-bottom: 25px;
}

.thank-you-page-dynamic-block-blank {
  width: 378px;
  height: 135px;
  border: 0.5px solid #ececec;
  box-sizing: border-box;
  border-radius: 3px;
  margin-left: 130px;
  position: relative;
}

.thank-you-page-dynamic-block {
  width: 378px;
  border: 0.6px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 3px;
  margin-left: 130px;
  position: relative;
  padding: 10px 15px;

  &-inside {
    padding-left: 7px;
    padding-top: 10px;
  }
  &-header {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", sans-serif;
    padding-bottom: 10px;
    margin-bottom: 17px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    color: #333333;
    font-size: 1.28571em;
    line-height: 1.3em;
  }
  p {
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  }
  &-scaled-container {
    transform: scale(0.7);
    width: 500px;
    height: initial;
    margin: -10%;
        margin-left: -10%;
    margin-left: -22%;
  }
}

#asset-container {
  align-items: center;
  background: #fbfbfb;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  position: relative;
  background-image: url("https://route-cdn.s3.amazonaws.com/route-widget-shopify/images/thank-you-asset/motion-asset-background-small.png");
  background-size: cover;
  height: 68px;
  font-size: 13.5px;
  line-height: 16px;
  margin-top: 10px;
}

#asset-container-small {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0 30px;
  width: 100%;
}

#asset-track-button {
  font-family: "Titillium Web", sans-serif;
  font-size: 10.2137px;
  line-height: 15px;
  align-items: center;
  background: #5dc8db;
  border-radius: 50px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  color: white;
  display: flex;
  font-weight: 600;
  justify-content: center;
  text-decoration: none;
  margin-top: 0px;
  height: 20px;
  left: 0;
  width: 100px;
  margin-left: 16px;
}

#asset-track-button:hover {
  background: #14869f;
}
#asset-logo-block {
  top: 0;
  width: 100%;
  height: 100%;
  position: none;
  padding: 0;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: -15px;
}
#asset-logo {
  height: 8px;
  width: 47px;
  padding-right: 30px;
  margin: 2px 0 0 6px;
}

#asset-opt-text {
  color: #a0a0a2;
  font-family: "Titillium Web", sans-serif;
  font-size: 5px;
  line-height: 8px;
  margin-right: 37px;
}

#asset-mobile-motion {
  height: 90.3px;
  width: 52.78px;
  margin-top: 25px;
  margin-left: -10px;
}

#asset-container-static {
  height: 68px;
  margin-top: 10px;
  display: flex;
  width: 100%;
  font-size: 16px;
  line-height: 30px;
  background-image: url("https://route-cdn.s3.amazonaws.com/route-widget-shopify/images/thank-you-asset/map-image-regular.png");
  background-size: cover;
  font-size: 9.75px;
  line-height: 16px;
}

#asset-track-button-static {
  align-items: center;
  background: #5dc8db;
  border-radius: 50px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  color: white;
  display: flex;
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  justify-content: center;
  margin: auto;
  text-align: center;
  text-decoration: none;
  width: 170px;
  height: 30px;
}

#asset-icon-static {
  padding-right: 5px;
  height: 15px;
  width: 18px;
}

#asset-track-button-static:hover {
  background: #14869f;
}
