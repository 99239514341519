@import '../../../_shared/_shared.scss';

.inputBox {
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  width: 218px;
  height: 42px;
  position: relative;
  z-index: 1;
  text-align: left;
}

.arrow {
  float: right;
  margin-right: 20px;
  margin-top: 10px;
}

.outer-calender-div {
  width: 218px;
  background-color: white;
  z-index: 1;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.12);
}

.calendar-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  color: #666666;
  font-style: normal;
  padding-left: 20px;
}

.DayPicker {
  display: inline-block;
  font-size: 0.7rem;
  width: 218px;
  //padding-top: 20px;
  padding: 18px 0px;
}

.DayPicker-Caption {
  text-align: center;
  margin-bottom: 15px;
}

.DayPicker-Caption > div {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
}

.DayPicker-wrapper {
  padding: 0px 18px;
}
.DayPicker-Week {
  margin-right: 10px;
  margin-left: 10px;
  padding: 5px;
}

.DayPicker-NavButton {
  margin-top: -12px;
}

.DayPicker-NavButton--prev {
  margin-right: 15em;
  left: 22.57%;
  background-image: url('../../../../assets/images/chevron-icon.svg');
  transform: rotate(90deg) scale(1.5);
}

.DayPicker-NavButton--next {
  right: 50px;
  background-image: url('../../../../assets/images/chevron-icon.svg');
  transform: rotate(270deg) scale(1.5);
  margin-top: -10px;
}

.DayPicker-Day--today {
  font-weight: 500;
}

.SelectedWeekExample .DayPicker-Day {
  outline: none;
  border: 1px solid transparent;
  border-radius: 0px;
}

.SelectedWeekExample .DayPicker-Day--hoverRange {
  background-color: #efefef !important;
}

.SelectedWeekExample .DayPicker-Day--selectedRange {
  background-color: #31c8e0 !important;
  border-top-color: #31c8e0;
  border-bottom-color: #31c8e0;
  border-left-color: #31c8e0;
  border-right-color: #31c8e0;
}

.SelectedWeekExample .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
.SelectedWeekExample .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
  border-radius: 0 !important;
  color: white !important;
}

.SelectedWeekExample .DayPicker-Month {
  border-collapse: separate;
  width: 218px;
  margin: 0;
}
.click-listener {
  opacity: 0;
  filter: alpha(opacity = 0);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 2;
  background: transparent;
}
