.free-tracking-email {
   .sample-image {
      max-width: 240px;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
      margin: 20px auto 10px auto;
      display: block;
   }

   .nested-bullet-list {
      li {
         margin-left:18px;
         width: 225px;

         span {
            margin-left: 17px;
         }

         .bullet {
            width: auto;
         }
      }
   }

   &-upper-text {
      margin-top: -15px;
   }

   .button {
      margin-top: 10px !important;
   }
}