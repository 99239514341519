@import '../_colors.scss';

.button {
    height: 40px;
    border-radius: 5px;
    margin-top: 20px;
    background: $route-teal !important;
    border: none;
    color: #fff !important;
    padding-top: 3px;
    font-family: $font !important;
    text-transform: none !important;
    font: {
        size: 15px !important;
        weight: 600 !important;
    }
    cursor: pointer;

    &.full {
        width: 100%;
    }

    &:disabled {
        background: lightgray !important;
        color: #000 !important;
    }

    &:focus {
        outline: none;
    }

    svg {
        margin-right: 10px;
    }

}

.button-hover-change {
    &:hover {
        background : #14869F !important;
        cursor: pointer;
    }
}
