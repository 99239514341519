@import "../../../../assets/styles/colors";

.connect-bank {

    &-upper-text {
        // text-align: justify;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 20px;
    }

    &-mid-text {
        font-style: italic;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        color: #666666;
        padding-top: 20px;
        padding-left: 30px;
        padding-right: 30px;
    }

    &-form-input {
        background: #FFFFFF;
        border: 1px solid rgba(49, 57, 66, 0.4);
        box-sizing: border-box;
        border-radius: 10px;
        width: 110px;
        height: 30px;
    }

    &-connect-bank-field {
        margin: 10px 0 15px;
        color: #333;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        // span {
        //       padding: 10px 0;
        //     }
        label {
            font-size: 16px;
            line-height: 19px;
            position: relative;

            color: #666666;
        }
    }

    &-routing-row {
        padding: 0 30px;
    }

    &-routing-input {
        font-size: 12px;
        line-height: 14px;
        width: 240px;
        height: 30px;
        margin: 11px 0 8px;
        padding: 5px;
        background: #FFFFFF;
        border: 1px solid #666666;
        box-sizing: border-box;
        border-radius: 0;
        position: relative;
    }

    &-footer {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 20px;
    }

    &-form-btn {
        width: 100%;
        margin-bottom: 15px;
        border-radius: 10px !important;
    }

    &-bank-options-divider-left,
    &-bank-options-divider-label,
    &-bank-options-divider-right {
        display: inline-block;
        width: 80px;
        text-align: center;


    }

    &-bank-options-divider-left,
    &-bank-options-divider-right {
        width: 110px;
        border-bottom: 1px solid #bbb;
    }
    &-bank-options-divider-label {
        text-transform: uppercase;
        font-weight: 600;
        color: #666666;
    }
    &-bank-options-routing-disclaimer {
        margin-top: 30px;
        margin-bottom: 25px;
        font-size: 16px;
        line-height: 19px;
        color: #666666;
        display: block;
        padding: 0 30px;

        &.first {
            margin-top: 15px;
            margin-bottom: 31px;
        }
    }

    &-bank-options-divider {
        padding: 0;
        text-align: center;
        width: 100%;
    }
}

.input-validation {
    position: relative;
    z-index: 2;
    top: -10px;

    &-box {
        top: 10px;
        background: #f06350;
        border-radius: 0 12px 12px 12px;
        position: absolute;
        width: 240px;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        color: white;
    }
    &-left-div {
        float: left;
        width: 15%;
        padding-top: 31px;
        padding-left: 15px;
    }

    &-right-div {
        float: left;
        width: 15%;
        padding-left: 20px;
        padding-top: 20px;
    }

    &-middle-div {
        float: left;
        width: 54%;
        padding-top: 11px;
        padding-bottom: 11px;
    }
}

.red-emphasis {
    color: red;
}

select.connect-bank-routing-input {
    position: relative;
    background: url('../../../../assets/images/chevron-icon.svg') no-repeat 95% 50%;
}

.connect-bank-container {


    .button {
        margin-top: -5px !important;
        margin-bottom: 7px !important;
    }
}
