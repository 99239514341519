.set-up-password-form {
   .legend {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      color: #666666;
   }

   .email-label {
      font-size: 12px;
      line-height: 14px;
      color: #666666;
      margin-top: 0px;
      display: block;
      margin-bottom: 27px;
      margin-left: 10px;
   }
}
