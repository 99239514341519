.sidedrawer {
   background: rgba(102, 102, 102, 0.2);

   .MuiDrawer-paper {
      background: #FFFFFF !important;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05) !important;
   }

   .sidedrawer-header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
      border: 0.5px solid #DEDEDE;
      flex-direction: column;
      position: relative;
      cursor: pointer;

      .title {
         font-family: Titillium Web, sans-serif;
         font-size: 16px;
         line-height: 19px;
         text-align: center;
         color: #666666;
         font-weight: 600 !important;
         padding: 35px 0;
      }

      .icon {
         position: absolute;
         top: 40px;
         right: 30px;
      }

      &.extended-plus {
         width: 300px;
         align-self: flex-end;
      }
   }

   .sidedrawer-content {
      width: 300px;
      height: calc(100% - 60px);
      flex-grow: 1;
      /* overflow-y: scroll; */

      &.extended-plus {
         width: auto;

         .portal-form-container {
            width: auto;
         }

         .portal-order-form {
            display: flex;
            height: calc(100vh - 90px);
         }

         .form-display-container {
            width: 301px;
            order: 1;
         }

         .form-web-order-tracking {
            order: 0;
            position: relative;
            left: 0;
            top: -85px;
            height: calc(100vh - 65px);
            /* top: 0; */
            overflow-y: auto;
            overflow-x: hidden;
            width: 681px;
            padding: 30px;

            /*&::-webkit-scrollbar { width: 0 !important } */

            > div {
               margin: 0 0 30px 0 !important;
               border: 0;

               > div {

                  &:nth-of-type(2), div.right {
                     padding-left: 0;
                     padding-right: 0;
                  }

                  > div {
                     &:nth-of-type(2) {
                        padding-left: 0;
                        padding-right: 0;
                     }
                  }
               }
            }


         }
      }

      &.extended {
         width: 600px;

         .form-display-container {
            display: flex;

            .primary-column, .second-column {
               display: flex;
               border-left: 0.5px solid #dedede;
               width: 300px;
               flex-direction: column;
               max-width: 300px;
               word-break: break-all;

               .form-block {
                  display: flex;

                  &.bordered {
                     position: relative;

                     &::before {
                        content: ' ';
                        position: absolute;
                        top: 0;
                        left: -30px;
                        width: calc(100% + 60px);
                        border-top: 0.5px solid #DEDEDE;
                     }
                  }
               }

               .form-group {
                  width: calc(100% - 20px);
               }

               .icon {
                  width: 25px;
                  margin: {
                     top: 20px;
                     right: 25px;
                  }

                  &.icon-small {
                     margin-top: 23px;
                     width: 22px;
                  }

                  &.icon-folder {
                     width: 18px;
                  }
               }
            }
         }
      }
   }
}
