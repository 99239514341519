@import '../../../mixins';
@import '../../colors';


.installation-instructions {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    height: calc(100vh - 90px);

    
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttons-container {
    margin: 15px 0 0;

    .button-container {
        text-align: center;

        &.expert-install {
            /* .title {
                position: relative;
                display: inline-block;
                cursor: pointer;

                &:before {
                    content: ' ';
                    width: 15px;
                    height: 15px;
                    background: url(../../../assets/images/information-icon.svg) no-repeat center center;
                    background-size: cover;
                    right: -20px;
                    position: absolute;
                }
            } */
        }
    }

    .title {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        font-family: $font;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        color: #313942;
        font-weight: bold;
        cursor: auto;
    }

    .text {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        font-family: $font;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 30px;
        text-align: center;
        color: #B4B4B4;
        margin-top: 25px;
    }

    .btn {
        margin-top: 35px;
    }
}