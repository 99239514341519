@import '../../../colors.scss';

.subtitle {
    font-family: $font;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 28px;

    font-family: Titillium Web;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */


    color: #666666;
}