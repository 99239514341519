@import '../../../_shared/_shared.scss';

table {
  width: 100%;
  cursor: default;
  border-collapse: collapse;
}

th {
  text-align: left;
}

.table-container {
  background-color: white;
  border: 0.5px solid $border-dark;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
}

.table-pagination {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.dense-table {
}

.table-row-data {
  &:hover {
    background-color: $route-teal;
    color: $white;
  }
}

.cancelled-order-row {
  color: #f00 !important;
  cursor: pointer;
}

.experimental-flex {
  margin-top: 30px;
  padding: 26px;
  border-radius: 24px;
  background-color: #ffffff;
}

.experimental-flex-left {
  margin: 0;
}

.hidden {
  display: none !important;
}
