@import "../../../../assets/styles/colors";

.verify-payment {

   &-upper-text {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
    font-weight: 300;
  }

  &-form-input {
    background: #FFFFFF;
    border: 1px solid #666666;
    box-sizing: border-box;
    height: 30px;
    width: 100%;
    border-radius: 0;
    padding: 10px 8px;
    font-size: 12px;
    line-height: 14px;
    padding:5px 5px 5px 19px;
  }

  &-form-deposit-row {
    display:inline-block;
    margin-top:10px;
    margin-bottom:20px;
    width: 100%;
    .field {
        margin-bottom:10px;
        color: #313942;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
    }

    &-one {
        display:inline-block;
        margin-right: 0;
        width: 100%;
        margin-bottom: 10px;
    }
  }

  &-form-btn{
    width:100%;
    margin-bottom:15px;
    border-radius:10px !important;
  }

  &-inner-container {
    padding: 20px 30px;

    p {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;
    }

    p + p {
      margin-bottom: 0;
    }
  }
}

.form-input-text {
	position:relative;
}

.static-value {
	position:absolute;
	left:10px;
	color: #666666;
	top:8px;
	font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}

.verify-payment-container {
  .button {
    margin-top: 0 !important;
    width: 100% !important;
    margin: 0 !important;
  }
}
