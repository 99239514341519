@import '../../../../assets/styles/colors';

.update-payment-method {
  b,
  strong {
    font-weight: 600;
    position: relative;

    &:before {
      content: '';
      width: 100%;
      position: absolute;
      bottom: 3px;
      border-bottom: 1px solid #666;
    }
  }
  i {
    font-style: italic;
    font-size: 15px;
  }

  &-connect-account,
  &-connect-card {
    background: #f5f5f5;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #fff;
    font-weight: 600;
    background: $route-blue;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.inactive {
      pointer-events: none;
      background: #ccc;
      color: #fff;
    }
  }
  &-icon {
    // padding-bottom: 0px;
  }

  &-icon-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: inherit;
    svg {
      margin-bottom: 10px;
    }
  }

  &-upper-text {
    // text-align: justify;
    font-size: 12px;
    line-height: 14px;
    a {
      font-weight: bold;
      text-decoration: underline;
      color: $route-blue;
    }

    padding: 20px 30px;
    padding-bottom: 0;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
  }

  &-lower-text {
    // width: 70%;
    text-align: left;
    margin-top: 20px;
    padding: 20px 30px;
    padding-top: 10px;

    p {
      margin-top: 10px;
      font-size: 16px;
      line-height: 19px;
      color: #666666;
    }

    .route-text {
      margin-top: 20px;
    }
  }

  &-understand-checkbox {
    display: flex;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    margin-bottom: 40px;

    p {
      margin-left: 10px;

      font-size: 16px;
      line-height: 19px;
      color: #666666;
    }

    .checkbox {
      margin-top: -10px;
    }
  }

  &-italics {
    font-style: italic;
  }

  &-container {
    padding: 0 30px;
  }

  .billing-video {
    font: {
      size: 16px;
      weight: 600;
    }
    line-height: 19px;
    text-decoration-line: underline;
    color: $route-blue;
    display: block;
    padding: 0 30px;
    margin: {
      top: 20px;
      bottom: 40px;
    }
  }
}

.plaid {
  color: #66c1cb;
  position: relative;
  font-weight: bold;

  sup {
    font-size: 9px;
    color: #666;
    position: absolute;
    top: -3px;
    font-weight: bold;
  }
}
