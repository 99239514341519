.custom-loadig-overlay {
   position: absolute;
   top: 0;
   width: 100%;
   height: 100%;
   z-index: -1;

   &._loading_overlay_wrapper--active {
      z-index: 1000;
   }
}