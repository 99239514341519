@import '../_shared/_shared.scss';
.portal-container-head {
  width: 100vw;
  height: 100vh;
}

.portal-container-large {
  // padding: calc(50px + 64px) 0 0 0;
  height: calc(100% - 22px);
  width: calc(100% - 108px);
  background: $background-light;
  overflow-y: auto;
  position: relative;
}

.notification-bar-verify-payment {
  width: 100%;
  height: 30px;
  background: #dc5960;
  position: sticky;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  span {
    margin-top: 7px;
    display: inline-block;
  }

  a {
    all: none;
    display: inline-block;
    text-decoration: underline;
    color: #ffffff;
  }
  a:hover {
    cursor: pointer;
  }
}
