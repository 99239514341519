.update-route-plus {

   .bullet-trace {
      width: 6px;
      height: 6px;
      margin-top: 7px;
   }

   .route-plus-image {
      margin: 0 auto;
      display: block;
      width: 240px;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
      margin-top: -10px;
   }

   .nested-bullet-list {
      padding: 0 30px 0 30px;

      li {
         width: 228px;
         margin-left: 16px;

         span {
            margin-left: 16px;
         }

         .bullet {
            width: auto;
         }

         .subnested-list {
            li {
               span {
                  width: 180px;
               }
            }
         }
      }
   }

   .button {
      margin-top: 10px !important;
   }
}