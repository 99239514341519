select {
    height: 40px;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    border: 1px solid #5ec8db;
    background-color: white;
    background-position: 98% 50%;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 28px;
    color: #757575;
    padding-left: 13px;
    border-radius: 15px;
    margin-bottom: 5px;
    background-position-x: calc(100% - 10px);
    
    &:focus {
        outline: none;
    }
}