.preferences-drawer-content-container {
  width: 300px;
  padding: 0;
  overflow-x: hidden;
}

.sidedrawer {
  font-family: Titillium Web, sans-serif;
  background: rgba(102, 102, 102, 0.2);

  .button {
    margin: 20px 30px;
    width: calc(100% - 60px);
    border-radius: 20px !important;
  }
}

.extended-drawer {
  width: 1000px;
  margin-bottom: -90px;
  letter-spacing: 0px;
}
