@import "../../../_colors.scss";

.save-state {
  &-container {
    position: relative;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 200%
    );
    z-index: 20000;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    &-content {
      display: flex;
      justify-content: center;
      z-index: 20100000000;
      opacity: 1;
      width: 300px;
      position: absolute;
      display: flex;
      flex-direction: column;
    }
  }
  &-header {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    font-size: 19px;
    line-height: 22px;
    color: #000000;
    text-transform: uppercase;
    text-align: center;
    padding: 30px 0;
    font-size: 19px;
    line-height: 22px;
  }

  &-text {
    margin: auto;
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    width: 200px;
    padding-bottom: 30px;
  }

  &-go-back-btn {
    background: #cccccc;
    border-radius: 20px;
    height: 40px;
    border-radius: 25px;
    width: 81%;
    margin: auto;
    border: none;
    color: #fff;
    padding-top: 3px;
    font-family: $font;
    text-transform: none;
    font: {
      size: 15px;
      weight: 600;
    }
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}
