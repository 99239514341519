@import '../../../../../App';

.nav-item {
   cursor: pointer;
   border-radius: 20px;
   margin: 8px 20px;

   .link {
      text-decoration: none !important;
   }

   .label {
    display: inline-block;
    margin-left: auto;
    margin-right: 10px;
   }

   .text {
      color: $gray-40;
      margin: 0 5px 0 15px;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      font-size: 14px;
   }

   .icon-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      color: #313942;
      height: 40px;
      padding: 1px 0;

      .icon {
         height: 20px;
         width: 20px;
         margin: 0 0 0 18px;
      }
    }

   &:hover {
      background: $primary-color;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);


      .icon-container {
         .text {
            color: white;;
         }

         &:not(.active-nav) {
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 20px;

            .text {
               color: $gray-40;
            }
         }

         .icon {
            color: #fff;
         }
      }
   }

   .active-nav {
      background: $primary-color;
      border-radius: 20px;
      color: white;
      font-weight: bold;

      .text {
         color: white;;
      }
    }

   /* &.bigger {
      &:hover {
         width: calc(100% + 58px);
      }
    }

    &.medium {
      &:hover {
         width: calc(100% + 38px);
      }
    } */
}
