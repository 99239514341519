.btn {
    border-radius: 36px;
    min-height: 40px;
    width: 240px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .25s ease;
    font-weight: 600;
    background: #66C1CB;
    border-radius: 20px;
    color: #fff !important;
    line-height: 19px;
    text-align: center;

    text-transform: none;
    font-weight: 600;
    font-size: 16px;
    font-family: Titillium Web;

    

    color: #FFFFFF;

    > span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
    }

    &.large {
        width: calc(100% - 30px);
    }

    &.medium {
        width: 247px;
    }

    &.disabled {
        border-color: #66C1CB;
        border-width: 1px;
        color: #BDBDBD;

        &:hover {
            background-color: #66C1CB;
            color: white;    
        }
    }

    &.light {
        border-color: #66C1CB;
        border-width: 1px;
        color: #272E31;

        &:hover {
            background-color: #66C1CB;
            color: white;    
        }
    }

    &.primary {
        background-color: #66C1CB;
        border-color: #66C1CB;
        border-width: 1px;
        color: white;

        &:hover {
            background-color: transparent;
            color: #272E31;
        }
    }

    &.basic {
        border: 0;
        background: none !important;
        margin-left: 30px;
        justify-content: flex-start;

        > span {
            line-height: 28px;
            color: #666666;
        }
    }

    &.underline {
        text-decoration-line: underline !important;

        > span {
            text-decoration-line: underline !important;
        }
    }

    &:disabled {
        background: lightgray !important;
        color: #000 !important;
     }
}