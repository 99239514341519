@import "../../../assets/styles/colors";

.set-up-billing {

    .line {
        border: 1px solid #000000;        
        width: 100%;
    }

    .play-video {
      margin-left: 30px;
    }

    .set-up-billing-play-video-wrapper {
        margin: {
          top: 28px;
          bottom: 20px;
        }

        img {
          cursor: pointer;
        }
    }

    .set-up-billing-info-list {
        font-size: 22px;
        vertical-align: middle;
        margin-top: 30px;
        list-style: disc;
        padding-left: 35px;

        li {
            font-weight: 300;
            
            line-height: 28px;
            margin: 15px 0;
            text-align: justify;

            color: #313942;
            ::marker {
                font-size: 3em;
            }
        }
    }

    .set-up-billing-accept-text {
        line-height: 29px;
        color: #313942;
        cursor: pointer;
    }

    .set-up-billing-play-video-text {
        text-decoration: underline;
        margin-left: 0.5rem;
        font-weight: 600;
        font-size: 30px;
        line-height: 30px;
        display: flex;
        align-items: center;
        text-decoration-line: underline;
    }
    
    .set-up-billing-play-video-wrapper {
        display: flex;
        align-items: center;
    }

    .choose-payment-method {
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;    
        background: #FFFFFF;
        border: 0.5px solid #313942;
        box-sizing: border-box;
        border-radius: 10px;
    
        &.inactive::before {
            content: ' ';
            position: absolute;
            background: rgba(255, 255, 255, 0.5);
            box-sizing: border-box;
            border-radius: 10px;
            width: 100%;
            height: 100%;
            z-index: 9;
        }
    
      &-title {
        font-size: 28px;
        line-height: 43px;
        text-align: center;
        font-weight: bold;
        margin-top: 15px;
    
        color: #313942;
      }
    
      &-connect-payment {
        vertical-align: middle;
        border-radius: 10px;
        padding: 10px;
        margin: 10px 0 10px 0;
        background: #f5f5f5;
        border: 1px solid rgba(49, 57, 66, 0.4);
        box-sizing: border-box;
        height: 81px;
        justify-content: center;
        position: relative;
        display: flex;
        &:hover {box-shadow: 1px 2px 3px 1px #5dc8db;}
        cursor: pointer;
    
    
        width: 100%;
      }
    
      &-header-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        
        &.recommended {
            padding-bottom: 20px;
    
            img {
                margin-top: 8px;
            }
        }
    
        .card-header {
          display: inline-block;
        }

        img {
          width: 2rem;
          margin-right: 6px;
        }
      }
    
      &-preferred-method {
        background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0) -0.14%,
          #68dae5 -0.13%,
          #1598af 100%
        );

        width: calc(100%);
        height: 1.5em;
        border-radius: 0 0 8px 8px;
    
        position: absolute;
        bottom: 0px;
    
        p {
          font-size: 11px;
          color: #fff;
        }
        display: flex;
        align-items: center;
        justify-content: center;
      }
      // }
    
      &-stripe-logo {
        width: 100px;
        margin-bottom: 10px;
      }
    
      hr {
        border-top: 0.0001em solid #e3e3e3;
        width: calc(100% + 40px);
        margin: 0 -20px 10px -20px;
      }
    
      &-stripe-info {
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 31px;    
        text-align: justify;
        letter-spacing: 0.1px;    
        color: #313942;
    
        .skip {
          text-align: right;
          text-decoration-line: underline;
          color: #313942;
          float: right;
          cursor: pointer;
          border: 0;
          margin-top: 4px;
        }
      }
    }

    .nested-bullet-list {
      i {
        font-style: italic;
        font-size: 15px;
      }
    }

    .button-container {
      margin-top: 30px;
    }
}