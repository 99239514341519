form.routeapp-form{
    margin-top: 20px;
    margin-left: -9px;
    width: calc(100% + 15px);

    .col-no-margin {
        margin-left: 0;
    }

    .row [class^="col"] {
        margin-bottom: 0 !important;
    }

    fieldset {
        border: none;
        margin-bottom: 15px;
        padding: 0;

        legend {
            font-family: Titillium Web;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #666;
            margin-bottom: 20px;
            padding-top: 10px;
            margin-left: 9px;
        }

        label {
            font-family: Titillium Web;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;

            color: #666666;
            margin-bottom: 10px;
        }

        input {
            background: #FFFFFF;
            border: 1px solid #666666;
            box-sizing: border-box;
            padding: 10px 10px 9px 10px;
            height: 30px;
            color: #666666;
            font-family: Titillium Web;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;

            &::placeholder {
                color: #666666 !important;
                opacity: 1;
            }
        }

        select {
            font-family: Titillium Web;
            background-color: #FFFFFF;
            border: 1px solid #666666;
            box-sizing: border-box;
            border-radius: 0;
            height: 30px;
            padding: 7px 10px;
            font-size: 12px;
            line-height: 12px;
            background-image: url('../../../../assets/images/chevron-icon.svg');
        }

        .input-phone {
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #666666;
            box-sizing: border-box;
            height: 30px;
            padding-left: 48px;
            padding-top: 10px;
            border-radius: 0;;
            font-size: 12px;
        }

        .button-input-phone {
            background-color: #ffffff;
            border-color: #666666;

            &.flag-dropdown.open-dropdown {
                border-radius: 0;

                .selected-flag {
                    border-radius: 0;
                }
            }

            .selected-flag {
                border-radius: 0;
            }
        }

        .btn {
            margin-top: 10px;
            margin-bottom: -1px;
            margin-left: 9px;
        }
    }

    .MuiFormControl-fullWidth {
        width: 100%;
        padding-right: 9px;
        padding-left: 9px;
    }


}

