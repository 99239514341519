.admin-container-large {
  .experimental-flex {
    display: flex;
    margin-top: 0 !important;

    &-right {
      min-width: 300px;
      max-width: 300px;
      display: inline-block;
    }
    &-left {
      min-width: 400px;
      display: inline-block;
      width: -webkit-fill-available;
      width: -moz-available;
      // margin-right: 20px;
    }
  }

  .portal-content-header-container--title {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    font-weight: 550;
    color: #313942;
  }
}
