.update-coverage-type {
   .attention-text {
      margin-top: 20px;
      margin-bottom: 13px;
   }
   .nested-list {

    padding-right: 15px;

    .MuiTypography-colorTextSecondary {
       margin-left: 0 !important;
    }

    .MuiListItemText-multiline {
       margin-top: 0;
    }

    .item-list {
       span {
         margin-top: -2px;
       }
    }
   }

   .coverage-type-coverage {
      display: flex;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #666666;
      cursor: pointer;


      border-top: 1px solid #666666;
      /* padding-top: 30px; */
      margin-top: 30px;
      margin-left: 30px;
      margin-right: 30px;
      padding: 0;
      padding-top: 30px;
      margin-bottom: 20px;

      .checkbox-square {
         border-top: 1px solid #666666;
    display: flex;
    align-self: self-start;
    padding: 0;
    margin-right: 30px;
      }
      img {
         height: auto;
      }

      b {
         &::before {
            display: none;
         }
      }
   }

   .button {
      margin-top: 10px !important;
   }
}