@import '../../_shared/_shared.scss';

.admin-preferences-main-container {
  width: calc(100% - 108px);
  background: $background-light;
}

.update-billing-contact-input-header {
  min-width: 39px;
  height: 19px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 20px 0px 10px;
  color: #666666;
}

.alert-left-div {
  float: left;
  width: 15%;
  padding-top: 23px;
  padding-left: 15px;
}

.alert-middle-div {
  float: left;
  width: 54%;
  padding-top: 11px;
}

.alert-right-div {
  float: left;
  width: 15%;
  padding-left: 20px;
  padding-top: 13px;
}

.validation {
  position: relative;
  z-index: 2;

  &-box {
    top: 10px;
    background: #f06350;
    border-radius: 0px 4px 4px 4px;
    position: absolute;
    width: 240px;
    height: 61.5px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    color: white;
  }
}

.input-ss {
  width: 236px;
  height: 26px;
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  border: 1px solid #666666;
  padding-left: 10px;
  &::placeholder {
    color: #bdbdbd;
  }
}

.bold-paragraph {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  height: 57px;
}

.admin-preferences-below-container {
  padding: 20px 0px;
}

.div-table {
  display: table;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 20px;
}

.div-table-row {
  display: table-row;
  height: 21px;
}

.div-table-cell {
  border: 0px solid #999999;
  display: table-cell;
  padding: 5px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  font-family: Titillium Web, sans-serif;
  color: #666666;

  &-content {
    display: table-cell;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #666666;
    font-family: Titillium Web, sans-serif;
  }

  &-content:before {
    content: '';
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 20%;
    border-left: 2px solid #bbbbbb;
    padding-right: 10px;
    padding-bottom: 5px;
    margin-left: 20px;
  }
}

.div-table-right-cell {
  display: table-cell;
  padding: 5px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1px;
  text-align: right;
  min-width: 81px;
  color: #666666;
}

.edit-cell-div {
  width: 15px !important;
  display: table-cell;
  padding: 5px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #666666;
}

.edit-links {
  width: 27px;
  height: 19px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  text-decoration-line: underline;
  float: right;
  color: $route-blue;

  &-grayed {
    color: #bbbbbb;
    text-decoration-line: underline;
    font-weight: 600;
    float: right;
  }

  &:hover {
    cursor: pointer;
  }

  &-small-edits {
    width: 21px;
    height: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    text-decoration-line: underline;
    color: $route-blue;

    &:hover {
      cursor: pointer;
    }

    &-grayed {
      color: #bbbbbb;
      text-decoration-line: underline;
      font-weight: 600;
    }
  }
}

.admin-preferences-card {
  padding: 0;
  border-radius: 0px !important;
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05) !important;
  font-size: 12px;
  line-height: 14px;
  color: #313942;

  &-above-card-title {
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    font-weight: 600;
    margin-top: 18px;
    position: relative;

    &.first {
      margin-top: 0;
    }
  }

  &-title {
    font-weight: 600;
    width: 35%;
    display: inline-block;
  }

  &-value {
    width: 65%;
    display: inline-block;
    text-align: right;
  }
  &-footnote {
    font-size: 12px;
    line-height: 14px;
    margin-top: 10px;
    text-align: center;
  }
  img {
    max-width: 275px;
    max-height: 150px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  &-update-company-details,
  &-update-payment-method {
    background: $route-blue;
    padding: 0 20px !important;
    align-self: flex-end;
    float: right;
    border-radius: 20px !important;
    font: {
      size: 15px;
      weight: 600 !important;
    }
  }
  &-row {
    display: flex;
    margin-bottom: 11px;
  }
}

.experimental-flex {
  display: flex;
  max-height: -webkit-fill-available;
  margin-bottom: 20px;

  &-right {
    min-width: 300px;
    max-width: 300px;
    display: inline-block;
    overflow-y: auto;
  }
  &-left {
    min-width: 400px;
    display: inline-block;
    width: -webkit-fill-available;
    width: -moz-available;
    margin-right: 0;
  }
}

.admin-preferences-options {
  display: flex;
  margin: 15px 0;
  .admin-preferences-option {
    width: 258px;
    margin-right: 30px;

    .admin-preferences-option-card {
      background: #ffffff;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
      min-height: 34px;
      display: flex;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #666666;
      align-items: center;
      padding: 0 20px;

      .text {
        flex-grow: 1;

        &.disabled {
          color: #dc5960;
        }
      }

      .tag {
        width: auto;
        background: $route-blue;
        border-radius: 20px;
        display: inline-block;
        padding: 2px 21px 2px 21px;
        color: #fff;
        cursor: pointer;

        &.disabled {
          background: #bbbbbb;
          cursor: initial;
        }
      }
    }
  }
}
