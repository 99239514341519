.first-column {
   background: #FFFFFF;
   box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
   padding: 30px !important;
   border: 0;

   &.no-padding {
      padding-bottom: 0 !important;
   }
}

.onboarding-container {
   max-width: 1440px !important;;
   width: calc(100% - 140px) !important;
   padding: 0 !important;;
   margin: 0 60px 0 60px !important;;
   height: 100% !important;;
}