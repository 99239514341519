//Route brand
$route-blue: #31c8e0;
$route-dusk: #51868e;
$route-dark: #023646;
$route-midnight: #222222;
$route-teal: $route-blue;
$teal-gradient-light: #7cd6e8;
$teal-gradient-dark: $route-blue;
$black: #333333;
$gray1: #666666;
$gray2: #999999;
$gray3: #cccccc;
$gray4: #dddddd;
$gray5: #eeeeee;

//Backgrounds
$background-dark: #313942;
$background-med: #e5e5e5;
$background-light: #fbfbfb;

//Text colors
$text-main: #313942;
$text-lighter: rgb(197, 200, 216);
$text-darker: dimgray;
$text-darkest: rgb(43, 43, 43);

//Misc
$white: #ffffff;
$med-grey: #949494;
$light-grey: #f3f2f2;

//Border box colors
$border-input-grey: rgba(49, 57, 66, 0.4);
$border-dark: rgba(49, 57, 66, 0.4);

$primary-color: #31c8e0;
$darker-primary-color: #1598af;
$text-color: #2a333b;
$border-dark: rgba(49, 57, 66, 0.4);

$gray-40: #666666;
