.password-issue-modal {
   position: absolute;
   top: 80px;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 10;
   -webkit-transition: all 0.5s 0.5s ease-in-out;
   transition: all 0.5s 0.5s ease-in-out;
   background: rgba(0, 0, 0, 0.5);
   opacity: 1;
   height: calc(100vh + 80px);

  &-wrapper {
    max-width: 349px;
    max-height: 85%;
    overflow: auto;
    position: absolute;
    top: calc(50% - 230px);
    left: 50%;
    margin-left: -174px;
    z-index: 2;
    background: #fff;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  &-header {
    border-bottom: 0.5px solid #BBBBBB;
    padding-bottom: 30px;
    padding: 40px 0;
    margin: 0 40px;

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }

  &-footer {
    border-top: 0.5px solid #BBBBBB;
    font-size: 12px;
    line-height: 16px;
    padding: 40px;
    display: flex;
    padding: 16px 40px;

    .new-account-user {
      margin-right: 10px;
    }
  }

  &-content {
    margin: 30px 0 8px 0;
    padding: 0 40px;
    color: #666666;

    .description {
      margin-bottom: 15px;
    }

    .button {
      width: 274px;
      border-radius: 20px;
      margin: 7px 0 7px 0;
    }

    b {
      font-weight: bold;
    }
  }

  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #F06350;
  }

  .highlight {
    color: #66C1CB;
  }

  &-success-screen {
    .password-issue-modal-title {
      font-weight: bold;
      font-size: 29px;
      line-height: 21px;
      color: #000000;
    }

    .password-issue-modal-content {
      margin-bottom: 25px;
    }
  }
}