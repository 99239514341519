html, body, #root, .onboarding-2, .App {
  height: 100%;
  margin: 0;
  padding: 0;
}

// This is necessary due to the "!important" override in src/components/_shared/_shared.scss
.onboarding-2 .MuiGrid-item {
  padding: 0 !important;
}
