.admin-subnav-container {
    display: inline-block;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 20px;
    align-items: center;
    height: 32px;
    margin: 40px 0 0 0;
    padding: 2px;
    .link {
        text-decoration: none;
        outline: none;
        height: 100%;
        display: inline-block;
        cursor: pointer;
    }
}

.admin-subnav-label {
    margin: 0;
    font-size: 13px;
    color: #666666;
    display: flex;
    align-items: center;
    padding: 0 24px;
    height: 100%;
    font-weight: 600;
    text-decoration: none;

    span {

    text-decoration: none;
    }
}

.active-admin-nav {
    background: #333333;
    border-radius: 16px;
    padding: 0px 24px 0px 24px;
    color: #fff;
    font-weight: 600;
}

.page-title {
    font-size: 32px;
    color: #313942;
}
