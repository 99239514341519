.drawer-title {
	text-align: center;
	display: flex;
	padding: 36px 0;
	border-bottom: 0.5px solid #dedede;
	position: relative;

	&-chevron {
		all: unset;
		float: none;
		position: absolute;
		cursor: pointer;
		left: 26px;
		margin-top: 2px;
	}
	&-close {
		border: 0;
		background: transparent;
		position: absolute;
		right: 30px;
		padding: 0;
		margin-top: 2px;
	}
	&-header {
		text-align: center;
		display: inline-block;
		margin-left: auto;
		margin-right: auto;
		line-height: 24px;
    color: #000;
    font-weight: bold;
    font-size: 24px;
    padding-right: 60px;
    padding-left: 20px;
	}
}

.extended-drawer {
  .drawer-title-header {
    font-size: 29px;
  }
}

.update-company-details-container {
	margin: 20px 30px;
}
