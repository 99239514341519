@import '../../assets/styles/colors';
@import '../_colors.scss';

.login {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  background-color: $background-light;
  justify-content: center;
  align-items: flex-start;
}

.login-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .login-password-error {
    text-align: center;
  }

  .login-header {
    width: 100%;
    height: 6%;
    background-color: $route-teal;
    justify-content: center;
  }
}

.login {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #f5f5f5;
}

.l-other-actions {
  height: 46px;
  padding-top: 8px;

  a {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;

    color: $route-blue;
    font-weight: 600;
  }

  .error-message {
    color: red;
    width: 100%;
    text-align: center;
    margin: 1em;
  }
}

.l-form {
  margin: 15px 0;

  .login-form {
    width: 100%;
    /* height: 225px; */
    max-width: 379px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;

    .login-input {
      height: 35px;
      width: 365px;
      margin-bottom: 15px;
      border: solid 1px $route-dark;
      border-radius: 4px;
      // padding: 5px;
      outline: none;
      font-size: 12pt;
      font-family: $font;

      background: #ffffff;
      border: 1px solid rgba(49, 57, 66, 0.4);
      box-sizing: border-box;
      border-radius: 10px;
    }

    .login-button {
      height: 40px;
      width: 100%;
      border-radius: 20px;
      margin-top: 15px;
      background: #66c1cb;
      border: none;
      color: white;
      font-size: 12pt;
      font-family: $font;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      font-size: 16px !important;

      &:disabled {
        background: lightgray;
      }
    }
  }
}

.login-password-error {
  text-align: center;
}

.form-descriptive-text {
  max-width: 310px;
  margin-bottom: 30px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #666666;
  white-space: pre-line;
}

.form-subtitle-text {
  margin-top: -15px;
}
