@import "../../_shared/_shared.scss";
.graph-container {
  margin-bottom: 0px;
  min-height: 270px;
  position: relative;

  canvas {
    height: 240px !important;
  }
}

.dash-line-graph-loading {
    margin-top: 13px;
    min-height: 270px;
    background-color: $background-med;
    position: absolute;
    top: 0;
    width: 100%;
    -webkit-animation: flickerAnimation 2s infinite;
    -moz-animation: flickerAnimation 2s infinite;
    -o-animation: flickerAnimation 2s infinite;
     animation: flickerAnimation 2s infinite;
  }

.dashboard-summary-card {
  border: 1px solid rgba(49, 57, 66, 0.4);
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 20px;
  border-radius: 10px !important;
  background-color: #fff;
  min-height: 300px;
  &-title {
    font-size: 20px;
    font-weight: 700;
  }
  &-subheading {
    font-size: 36px;
    line-height: 28px;
    font-weight: 675;
    margin-bottom: 15px;
    margin-top: 15px;
    color: #66C1CB;

    &-first {
      line-height: 42px;
      height: 42px !important;
    }

    &.dash-loading-state {
      line-height: 28px;
      height: 28px !important;
    }
  }
  &-premiums {
    font-weight: 475;
    margin-bottom: 10px;
  }
  &-subtotal {
    font-weight: 600;
  }
  &-left {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    margin-bottom: 0;
    color: #66C1CB;
    margin-right: 10px;

    &.dash-loading-state {
      height: 30px;
      width: 74px;
      /* float: left; */
      margin-bottom: 0;
      display: inline-block;
      margin-right: 10px;
    }

    &-medium {
      color: #313942;
      // font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
    &-small {
      color: #313942;
      // font-weight: 600;
      font-size: 10px;
      line-height: 14px;
    }
  }
  &-range {
    display: flex;
    justify-content: space-between;
    font-size: 12.8px;
    margin-top: 5px;

    .dash-loading-state {
      max-width: 89px;
      height: 16px;
    }
  }
  &-right {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    &-medium {
      // font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      float: right;
    }
    &-small {
      // font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      float: right;
    }
  }
  &-alert {
    color: #f00;
    margin-bottom: 20px;
    margin-top: 18px;
  }

  & hr {
    border: 0.5px solid #aaa;
    margin: 40px;
    width: 80%;
  }
}

.chartjs-render-monitor {
  padding-top: 40px;
}

.dash-loading-state {
  width: 100%;
  height: 100%;
  background-color: $background-med;
  -webkit-animation: flickerAnimation 2s infinite;
  -moz-animation: flickerAnimation 2s infinite;
  -o-animation: flickerAnimation 2s infinite;
   animation: flickerAnimation 2s infinite;
}


@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0.65; }
    100% { opacity:1; }
  }
  @-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.65; }
    100% { opacity:1; }
  }
  @-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.65; }
    100% { opacity:1; }
  }
  @-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.65; }
    100% { opacity:1; }
  }

.dashboard {
  // .MuiGrid-item {
  //   padding: 0 !important;
  // }

  .MuiGrid-spacing-xs-3 {
    margin: 0px;
}

  .graph-side {
    margin-left: 60px;
  }
}