li.list-item {
    font-size: 24.9028px;
    line-height: 40px;
    font-weight: bold;
    position: relative;

    &.normal {
        font-weight: normal;
    }

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: -33px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        top: 14px;
        background: #313942
    }
}