@import "../../../../assets/styles/colors";
$font : 'Titillium Web';
$text : #313942;

@mixin btn-mixin {
  border-radius: 3px;
  border-style: none;
  height: 35px;
  width: 100%;
  background-color: $route-teal;
  color: white;
  font-size: 15px;
  font-family: $font;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}


.shopify-billing {
  display: grid;
  justify-content: center;
  align-items: center;

  .sb-header {
    width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    color: #313942;
    font-family: $font;
    font-weight: 400;
    margin: 25px 0 0 0;
    justify-self: center;
  }

  p {
    text-align: center;
  }

  .fine-print {
    font-size: .8em;
    margin-top: 30px;
    text-align: center;

    p {
      margin: 0;
    }
  }

  .sb-understand-billing {
    justify-self: center;
    margin: 10px 0px 20px 0px;

    .sb-understand-billing-link {
      cursor: pointer;
      color: $route-teal;
      text-align: center;
      font-weight: bold;
      transition: all .3s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }

    .sb-understand-billing-check {
      text-align: center;
      margin-top: 15px;
      font-size: 15px;
    }

    .sb-understand-billing-check input {
      cursor: pointer;
      vertical-align: middle;
      height: 20px;
      width: 20px;
    }
  }
}

.shopify-plaid {
  display: flex;
  justify-content: center;

  .billing-success {
    display: flex;
    justify-content: center;
    align-items: center;

    .bs-next-button {
      width: 150px;
      height: 35px;
      margin: 0 0 0 7px;
      background-color: $route-teal;
      color: rgb(255, 255, 255);
      border: none;
      border-radius: 3px;
      font-family: $font;
      font-weight: 600;
      font-size: 12pt;
    }
  }

  .billing-needed {
    display: flex;
    //justify-content: center;
    //align-items: center;
    flex-direction: column;

    .skip-button {
      height: 35px;
      //margin: 0 0 0 7px;
      background-color: transparent;
      color: rgb(172, 171, 171);
    }

    .skip-button:focus {
      outline: 0;
    }

    &-method-label {
      width: 199px;
      color: $route-teal;
    }

    &-method-card-icon,
    &-method-bank-icon {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .fa-plus {
        position: relative;
        left: 2px;
      }
    }

    &-method-card,
    &-method-bank {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      align-items: center;
      align-content: center;

      padding: 15px;
      height: 60px;
      width: 355px;
      &:hover {
        cursor: pointer;
      }
    }

    &-method-bank,
    &-method-card {
      position: relative;
      top: 5px;

      border-top: gray 1px solid;
    }
  }
}

@media all and (max-width: 667px) {
  .shopify-billing {

    .sb-header {
      width: 375px;
    }

    p {
      width: 235px;
    }

    .fine-print {
      width: 275px;
      justify-self: center;
      text-align: center;
    }
  }
}

.bank-options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 490px;
  font-family: $font;
  &-routing-disclaimer {
    position: relative;

  }
  &-divider {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    width: 60%;
    top: -30px;
    &-label {
      color: $text;
      font-family: $font;
      padding: 10px;
    }
    &-left, &-right {
      height: 1px;
      width: 100%;
      background-color: $text;
    }
  }

  &-connect-routing {
    @include btn-mixin;
    position: relative;
    bottom: -20px;

  }

  &-connect-instantly {
    @include btn-mixin;
    position: relative;
    top: -40px;
    margin-bottom: 10px;
    width: 300px;
  }
}

.bank-routing-form {
  display: flex;
  justify-content: space-around;
  height: 300px;
  width: 550px;
  padding: 10px;
  border: 1px solid $route-teal;
  border-radius: 5px;

  .bank-routing-form-container {
    display: grid;

    @mixin form-rows {
      height: 35px;
      width: 550px;

      .field {
        display: grid;
        grid-template-columns: [label-s] 150px [lable-e input-s] 350px [input-e];
        height: 35px;
        border-bottom: 1px solid $route-teal;
        padding: 3px 0 3px 0;

        label {
          display: grid;
          grid-column: label-s/lable-e;
          align-items: center;
        }

        input {
          grid-column: input-s/input-e;
          //color: $text;
          font-family: $font;
        }

        input:focus {
          outline: none;
        }
      }
    }

    .routing-input {
      border: none;
      grid-column: input-s/input-e;
      font-size: 15px;
      font-family: $font;
      &:focus {
        outline: none;
      }
    }

    #account-holder {
      background-color: white;
      &:hover {
        cursor: pointer;
      }
    }

    .routing-row {
      @include form-rows;
    }
  }
}

video:focus {
  outline: none;
}