
@import './reset';
@import './mixins';
@import './assets/styles/core';

.App {
  font-family: 'Titillium Web', sans-serif;
}

.text-right {
  text-align: right;
}

.MuiDrawer-modal {
    width: 0px !important;
}

.react-tel-input {
  margin-top: 8px;

  .form-control {
    height: 30px;
    font-size: 11.5px;
    box-sizing: border-box;
    line-height: 14px;
    max-width: 100%;
  }
}

.react-tel-input .selected-flag .arrow {
  left: 30px;
}


