.onboard-complete {
    .btn {
        margin-top: 15px;
    }

    strong, b {
        font-weight: bold;
    }

    .light {
        margin-left: 80px;
    }
}

.column {
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05) !important;
    padding: 29px 30px 30px 30px;
}

.extra-paragraph {
   display: block;
   text-align: left;
   margin-top: 35px;
   margin-bottom: 0;

   a {
      color: #66c1cb;
   }
}