.update-route-email {
   .button-container {
      margin-top: 30px;
      
      .btn {
         &.basic {
            width: auto;
        }
      }
   }
}