@import '../_shared.scss';

$border-radius-size: 20px;

.daterange-picker {
  display: inline-flex;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #666666;
  position: relative;
  width: 100%;
  align-items: center;

  &-enter-active {
    z-index: 100000;
  }

  .daterange-title {
    margin-right: 20px;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #333333;
  }

  .placeholder {
    display: block;
    max-width: 285px;
    background: #ffffff;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    padding: 10px 20px;
    text-align: left;
    color: #bbbbbb;
    position: relative;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &.placeholder-enter-active {
      color: #666666;
    }

    .icon {
      position: absolute;
      right: 20px;
      cursor: pointer;
    }
  }

  .divider {
    margin-left: 10px;
    margin-right: 10px;
  }

  /* .dropdown-container {
      position: absolute;
      width: 100%;
   } */

  .dropdown {
    position: absolute;
    right: 0;
    width: 100%;
    background: #ffffff;
    text-align: left;
    height: 0px;
    overflow-y: auto;
    z-index: 999;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    &.dropdown-enter-active {
      height: auto;
    }

    &.dropdown-enter-full-height-active {
      height: 282px;
    }

    &.dropdown-leave-active {
      height: 0px;
    }

    .dropdown-item {
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      padding: 13px 20px 10px 20px;
      cursor: pointer;

      > span {
        display: block;
        height: 100%;
        width: 100%;
        cursor: pointer;
      }
    }
  }

  .daterange {
    display: block;
    width: 285px;
    position: relative;
  }
}

.daterange-picker-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(102, 102, 102, 0.2) !important;
  left: 0;
  top: 0;
  z-index: 9998;
}

.rs-picker-menu {
  border-radius: 16px !important;
  padding: 25px;
  font-family: 'Titillium Web', sans-serif;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16) !important;
  margin-top: 25px;
}

.rs-picker-daterange-menu {
  /* &:before {
    top: -10px;
    content:"\A";
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent black transparent transparent;
    position: absolute;
    left: 50%;
    transform: rotate(90deg);
    border-radius: 4px;
    z-index: 999;
  } */
}

.rs-picker-toolbar-ranges {
  display: none !important;
}

.rs-picker-daterange-menu .rs-picker-toolbar {
  margin-top: 0px !important;
  border-top: 1px solid rgba(51, 51, 51, 0.12);
  z-index: 99;
  position: relative;
  padding-top: 15px;
  padding-right: 0;
}

.rs-picker-toolbar-right-btn-ok {
  background: $route-blue !important;
  border-radius: $border-radius-size !important;
  width: 60px !important;
  height: 30px !important;

  span {
    position: relative;
    &:after {
      position: absolute;
      content: 'Apply';
      background: $route-blue;
      left: -4px;
      font-size: 12px;
      line-height: 16px;
      top: 0;
      font-weight: 600;
      font-family: 'Titillium Web', sans-serif;
    }
  }
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 12px;
  padding: 0 !important;
  z-index: 99 !important;
}

// .rs-calendar:nth-child(1) .rs-calendar-header-month-toolbar {
//   position: relative;
//   margin-top: 10px;

//   &::after {
//     display: block;
//     content: 'Start Date' !important;
//     position: absolute;
//     top: -20px;
//     width: 200px;
//     text-align: center;
//     font-family: Titillium Web;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 10px;
//     line-height: 11px;
//     text-align: center;
//     color: #999999;
//   }
// }

// .rs-calendar:nth-child(2) .rs-calendar-header-month-toolbar {
//   position: relative;
//   margin-top: 10px;

//   &::after {
//     display: block;
//     content: 'End Date' !important;
//     position: absolute;
//     top: -20px;
//     width: 200px;
//     text-align: center;
//     font-family: Titillium Web;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 10px;
//     line-height: 11px;
//     text-align: center;
//     color: #999999;
//   }
// }

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background: $route-blue !important;
  border-radius: $border-radius-size;
  padding: 0;
}

.rs-calendar-table-cell-in-range::before {
  background: #14869f !important;
  height: 30px !important;
  color: #ffffff !important;
}

.rs-calendar-table-cell-selected,
.rs-calendar-table-cell-in-range {
  color: #ffffff !important;

  &:first {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
  }
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  padding: 0 !important;
  border-radius: 50%;
}
.rs-picker-menu {
  margin-top: 10px !important;
}

.rs-picker-menu,
.rs-picker-daterange-calendar-group {
  width: 400px !important;
  min-width: 400px !important;
  /*height: 303px !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.rs-picker-daterange-calendar-group {
  margin-top: 18px;
  position: relative;

  &::before {
    position: absolute;
    height: 16px;
    width: 16px;
  }
}

.rs-picker-daterange-menu .rs-calendar {
  width: 200px !important;
}

.rs-calendar-table-cell-selected::before {
  z-index: 2 !important;
}

.rs-calendar-table-cell-in-range::before {
  z-index: 1 !important;
}

.rs-calendar-table-cell-selected-start ~ .rs-calendar-table-cell.rs-calendar-table-cell-in-range {
  &:after {
    content: ' ';
    position: absolute;
    background: #14869f;
    height: 30px;
    width: 33px;
    left: -27px;
    top: 3px;

    margin-left: 2px !important;
    border-top-left-radius: $border-radius-size;
    border-bottom-left-radius: $border-radius-size;
  }
}

.rs-calendar-table-cell.rs-calendar-table-cell-in-range ~ .rs-calendar-table-cell-selected-end {
  &:after {
    content: ' ';
    position: absolute;
    background: #14869f;
    height: 30px;
    width: 30px;
    right: 3px;
    top: 3px;
    border-top-right-radius: $border-radius-size;
    border-bottom-right-radius: $border-radius-size;
  }
}

.rs-picker-daterange-menu .rs-calendar:first-child {
  border: 0 !important;
}

.rs-calendar-table-cell-selected span {
  margin-top: 2px;
}

.rs-picker-daterange .rs-btn .rs-picker-toggle-value,
.rs-picker-daterange .rs-picker-toggle .rs-picker-toggle-value {
  color: #cccccc !important;
}

.rs-picker-daterange.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-daterange.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #2c373c !important;
}

.rs-picker-default:hover .rs-picker-toggle.rs-btn,
.rs-picker-default .rs-picker-toggle.active.rs-btn {
  border: 1px solid #2c373c !important;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  right: 11px !important;
  background: #f7f7f7 !important;
  width: 16px !important;
  z-index: 99 !important;
  border-radius: 50% !important;
  color: #333 !important;
  display: block !important;
  font-size: 12px !important;
  height: 16px !important;
  text-align: center !important;
  top: calc(50% - 9px) !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-right: 42px !important;
  width: 165px;
  font-size: 12px !important;
}

.rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn {
}

.rs-picker-has-value .rs-picker-toggle-caret {
  display: none;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  background: url('../../../assets/images/calendar.svg') no-repeat !important;
  width: 14px !important;
  height: 14px !important;

  &::before {
    content: ' ' !important;
  }
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border: 1px solid #eeeeee !important;
  background: #eeeeee !important;
  color: #333 !important;
}

.rs-calendar-table-cell {
  cursor: pointer;
}

.rs-calendar-table-header-row {
  color: #cccccc !important;
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 8px;
  /* identical to box height, or 100% */

  text-align: center;
  text-transform: uppercase;

  /* Gray 3 */

  color: #cccccc;
}

.rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  color: inherit !important;
}

.rs-calendar-table-cell-in-range:hover .rs-calendar-table-cell-content {
  background: transparent !important;
  color: #fff !important;
}

.rs-calendar-view {
  padding: 0 !important;
  margin: 0 5px;
  margin-top: 17px !important;
}

.rs-calendar-table-cell-in-range {
  &:first-child {
    &:before {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }

  &:last-child {
    &:before {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}

.rs-picker-daterange-header {
  display: none !important;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward {
  float: none !important;

  &:before {
    display: block;
    width: 7px;
    height: 7px;
    background: url('../../../assets/images/dropdown-arrow.svg') no-repeat center center;
    content: '';
  }
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward {
  float: none !important;

  &:before {
    display: block;
    width: 7px;
    height: 7px;
    background: url('../../../assets/images/dropdown-arrow.svg') no-repeat right center;
    content: '';
    transform: rotate(180deg);
    float: right;
  }
}

.rs-calendar-header-title {
  font-family: Titillium Web !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: center !important;
  color: #333333 !important;
}

.rs-calendar-table-cell-un-same-month {
  opacity: 0; // hide calendar cell for un same month
}
