@import '../../../../assets/styles/colors';

.image-notification {
  &-container {
    align-items: center;
    background: #333;
    border-radius: 15px;
    box-shadow: 0px 14px 18px rgba(0, 0, 0, 0.15);
    color: white;
    display: flex;
    flex-direction: column;
    margin-left: -25px;
    height: 319px;
    width: 280px;
    margin-bottom: 10px;
    position: absolute;
    width: 280px;
    left: 0;
    z-index: 19000;
  }

  &-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0 15px 0;
    height: 35px;
    justify-content: space-between;
  }

  &-icon-arrow {
    &-outer {
      position: absolute;
      top: -25px;
      left: 0;
      left: calc(50% - 20px);
      z-index: 9999;
    }
    &-inner {
      transform: rotate(45deg);
      background-color: #333;
      width: 20px;
      height: 20px;
      top: 16px;
      left: 10px;
      position: absolute;
      border-radius: 4px;
    }
  }

  &-close-icon-container {
    background: #fff;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
    transition: all 0.5s;

    svg {
      height: 7px;
      width: 7px;
      margin-left: -1px;
    }

    &:hover {
      background: #65c1cb;

      svg {
        path {
          fill: #fff !important;
        }
      }
    }
  }

  &-header {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    padding: 0 40px
  }

  &-dimension-error {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding: 0px 40px;
    text-align: center;
  }

  &-description {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    padding: 24px 45px;
    text-align: center;

    &-blue {
      color: #31C8E0
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    // color: red;
    // text-align: center;
    ul {
      padding: 15px 0;
    }

    li {
      display: flex;
      align-items: center;
      margin: 10px 0;
      div {
        margin-right: 10px;
      }
    }
  }
  &-desc {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
  }

  &-sub-header {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    div {
      font-size: 14px;
      line-height: 22px;
      font-style: normal;
      font-weight: normal;
      margin: 5px 0 20px 0;
    }
  }
}

.image-notification-close-button {
  align-items: center;
  background: $route-blue;
  bottom: 0;
  width: 57px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  font-size: 13px;
  justify-content: center;
  justify-self: flex-end;
  line-height: 16px;
  position: absolute;
  text-transform: uppercase;
  text-transform: none;
  bottom: 30px;
  padding-bottom: 3px;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
}


