.shopify-implementation {
   &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #666666;
      margin-top: 20px;
   }

   &-link {
      color: #66C1CB;
      text-decoration: underline !important;
   }

   .button {
      margin-top: 10px !important;
   }
}