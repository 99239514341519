@import '../../assets/styles/core';

.header {
    width: 100%;
    text-align: center;
    /*background: $primary-color; */
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    padding: 16px 0;

    .route-logo {
        width: 200px;
        margin-left: 60px;

        &.onboarding-route-logo {
            margin: 0;
        }
    }
}