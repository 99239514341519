@import '../../_shared/_shared.scss';

.invoice-summary-card {
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05) !important;
  padding: 12px 20px;
  border-radius: 0 !important;
  min-width: 178px;

  &-title {
    font-size: 20px;
    font-weight: 700;
  }
  &-subheading {
    font-weight: 350;
    margin-bottom: 20px;
  }

  &-alert {
    color: #f00;
    margin-bottom: 20px;
    margin-top: 18px;
  }
  .input-button {
    border-radius: 10px !important;
  }
}

.summary-widget {
  margin-right: 30px;

  .summary-title {
    text-align: left;
    font-size: 16px;
    color: $gray1;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .summary-block {
    display: flex;

    .summary-price {
      font-size: 24px;
      line-height: 18px;
      color: $route-blue;
      min-width: 50px;
      font-weight: bold;
      text-align: left;
    }

    .invoice-summary-card-premium {
      display: flex;
    }

    .summary-text {
      font-size: 16px;
      line-height: 19px;
      text-align: right;
      margin-left: 10px;
      color: $gray1;
      flex-grow: 1;
      font-weight: bold;
    }
  }
}
