@import '../../_shared/_shared.scss';

.claims-rate-dial-content-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.claims-rate-table-content-container {
  width: 1150px;
  height: 100%;
  display: flex;
}

.claim-insights-table-container {
  width: 370px;
  display: inline-block;
  margin-right: 30px;

}
.claim-insights-dial {
  display: inline-flex;    
  margin-right: 30px;
  z-index: 1;
}
.claims-insights-dial-container {
  width: 1150px;
  display:flex;
  justify-content: auto;

}
.claim-insights-card-wrapper {
  margin-right: 30px;
}
.claim-insights-info{     
  box-shadow: 10px 20px 30px 10px #000;
  cursor: pointer;
} 
.claims-content-container {
  width: 100%;
  height: 100%;
  margin-top: -10px;
  padding: 0px 0px 30px 58px;
  display: block;
  box-sizing: border-box;
}