@import '../../../../assets/styles/colors';

.update-company-details {
  display: flex;
  flex-direction: row;
  padding: 92px 0 170px 0;

  &-header {
    width: 1000px;
    display: fixed;
    position: fixed;
    background-color: white;
  }

  &-save-state-container {
    margin-top: 92px;
    width: 100%;
    height: calc(100vh - 92px);
    position: absolute;
    z-index: 1000000;
  }

  &-mobile-preview {
    width: 600px;
  }
  &-container {
    width: 300px;
  }
  header {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    padding-top: 25px;
  }
  &-button {
    background: #fff;
    border: 1px solid rgba(49, 57, 66, 0.4);
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    width: 100%;
    height: 60px;

    background: #ffffff;
    border: 1px solid #666666;
    box-sizing: border-box;
    border-radius: 0;
    margin: 10px 0;
    margin-top: 0;

    &.inactive {
      pointer-events: none;
      opacity: 0.65;
    }
  }

  &-icon {
    display: inline-block;
    width: 30px;
    margin-right: 10px;
  }
  &-instructions {
    display: inline-block;
    span {
      font-size: 12px;
      line-height: 14px;
      text-decoration-line: underline;
      color: $route-blue;
      display: inline;
    }
  }

  &-section-title {
    margin: 30px 0 10px 0;
    color: #666;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    &-small {
      margin: 15px 0;
    }
    position: relative;
  }
  &-recommended-size {
    width: 100%;
    display: inline-block;
    line-height: 14px;
    margin-bottom: 10px;
    color: #666;

    font-size: 16px;
    line-height: 19px;
    color: #666666;
    text-align: left;
  }

  &-filename {
    text-align: center;
    margin-bottom: 10px;
  }

  &-save-container {
    box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.05);
    width: 100%;
    bottom: 0;
    background: #ffffff;
    display: flex;
    position: fixed;
    z-index: 10;
    padding-left: 280px;
  }

  &-save-button {
    width: 350px;
  }

  .button {
    margin: 0 30px !important;
  }

  .merchant-logo-container {
    width: 100px;
  }

  .store-logo,
  .store-hero {
    border-radius: 10px;
    object-fit: contain;
  }

  .store-logo {
    width: 100px;
    height: 100px;
    margin: 10px 0;
  }

  .store-hero {
    img {
      position: relative;
      width: 250px;
    }
    width: 100%;
    height: 153.6px;

    // height: calc(153.6px - 17px);
    margin: 10px 0;
    margin-bottom: 0;
  }

  .blank-image-placeholder-hero {
    position: relative;
    height: 140px;
    width: 300px;
    border: 1px dashed #14869F;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      z-index: 1;
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
      align-items: center;
      text-align: center;
      color: #CCCCCC;
    }
  }

  .blank-image-placeholder-hero-inside-box {
    border: 1px dashed #5DC8DB;
      position: absolute;
      height: 140px;
      width: 197px;
  }

  .cover-image-preview-link {
    font-style: normal;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-decoration-line: underline;
    margin-top: 10px;
    color: #66C1CB;
  }

  .blank-image-placeholder-logo {
    position: relative;
    height: 100px;
    width: 100px;
    text-transform: uppercase;
    color: white;
    font-size: 14px;
    line-height: 17px;
    background: #2c373c;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      z-index: 1;
    }
  }

  .blank-image-placeholder-logo-line {
    position: relative;
    &-right {
      border: 0.5px solid #a0a0a2;
      position: absolute;
      width: 140px;
      transform: rotate(45deg);
    }
    &-left {
      border: 0.5px solid #a0a0a2;
      position: absolute;
      width: 140px;
      transform: rotate(135deg);
    }
  }

  .routing-row {
    .routing-input {
      background: #ffffff;
      border: 1px solid #666666;
      box-sizing: border-box;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #666666;
      width: 100%;
      margin-bottom: 10px;
      padding: 10px 7px 9px 7px;
      height: 30px;
    }

    .routing-text-area {
      background: #ffffff;
      border: 1px solid #666666;
      box-sizing: border-box;
      min-height: 164px;
      padding: 18px 20px 19px 18px;
      margin-top: 8px;
      width: 100%;
      color: #666666;
      font-size: 12px;
    }
  }

  .routing-message {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #666666;
    margin-top: -8px;
    display: block;
    letter-spacing: 0px;
  }
}

section {
  margin: 20px 36px 20px 36px;
}

#line-break {
  width: calc(100% - 1px);
  height: 0px;
  margin: 20px 0;
  border: 0.5px solid #e6e6e6;
}

.update-company-details-recommended-size {
  padding-top: -10px;
  font-size: 11px;
  line-height: 13px;
  position: relative;
}

.delete-img-bar {
  &-logo {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 10px;
    background: #f06350;
    height: 27px;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: white;
    position: relative;
    &:hover {
      padding-top: 111px;
      z-index: 5;
      margin-top: -111px;
      background: #f06350;
      opacity: 0.25;
    }
    span {
      opacity: 1;
      padding-left: 8px;
    }
    img {
      padding-bottom: 2px;
    }
  }
  &-hero {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 10px;
    background: #f06350;
    height: 27px;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: white;
    position: relative;
    span {
      opacity: 1;
      padding-left: 10px;
    }
    img {
      padding-bottom: 2px;
    }
    &:hover {
      padding-top: 154px;
      z-index: 200;
      margin-top: -154px;
      background: #f06350;
      opacity: 0.25;
    }
  }
}

.image-req-tool-tip {
  cursor: pointer;
  color: #333333;
  font-weight: bold;
  display: inline-block;
  margin-left: 5px;
  text-decoration: underline;
  transition: all 0.5s;
  &:hover {
    color: rgb(93, 200, 219);
  }
}

input,
textarea,
span {
  font-family: 'Titillium Web';
}

.crop-buttons {
  background: #f7f7f7 !important;
  color: black !important;
}
